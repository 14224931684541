// Fonts
@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700,900");

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import "~slick-carousel/slick/slick";
@import "~slick-carousel/slick/slick-theme";

.navbar-laravel {
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}

.payment-strip {
    background: white;
    padding-top: 1em;
    padding-bottom: 1em;
    padding-left: 1em;
}

.payment-strip img {
    max-height: 2em;
    margin-right: 1em;
}

.text-black {
    color: black;
}
